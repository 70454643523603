import React from 'react'
import { Layout, Footer, ContactBanner, Section, SEO } from '../../components'
import { useTranslation } from 'react-i18next'

const ForeignTrade = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t('titlePages:foreignTrade')} />
      <Section title='foreignTrade:title' text='foreignTrade:main-text' />
      <ContactBanner />
      <Footer />
    </Layout>
  )
}

export default ForeignTrade
